<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="750"
  >
    <v-card :loading="isLoading">
      <v-card-title>
        <div class="text-center">
          <h4>Masukkan Info Tambahan</h4>
        </div>
        <v-row>
          <v-col class="pa-0 d-flex justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-row class="mt-0">
            <v-col class="pb-0" cols="12" sm="4">
              <label for="periodStart">
                Periode
                <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
              </label>
            </v-col>
            <v-col cols="auto" sm="4" class="pt-0">
              <v-menu
                ref="menuPeriodStart"
                v-model="menuPeriodStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    id="periodStart"
                    dense
                    outlined
                    :value="form.periodStart ? dateFormat(form.periodStart) : ''"
                    :rules="[v => !!v || $_strings.messages.validation.REQUIRED('Dari tanggal')]"
                    class="body-2"
                    placeholder="Dari tanggal"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.periodStart"
                  no-title
                  scrollable
                  :max="form.periodEnd ? dayjs(form.periodEnd).format('YYYY-MM-DD') : ''"
                  @change="menuPeriodStart = false"
                  locale="id"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="auto" sm="4" class="pt-0">
              <v-menu
                ref="menuPeriodEnd"
                v-model="menuPeriodEnd"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    :value="form.periodEnd ? dateFormat(form.periodEnd) : ''"
                    class="body-2"
                    :rules="[v => !!v || $_strings.messages.validation.REQUIRED('Sampai tanggal')]"
                    placeholder="Sampai tanggal"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.periodEnd"
                  no-title
                  :min="form.periodStart ? dayjs(form.periodStart).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')"
                  scrollable
                  locale="id"
                  @change="menuPeriodEnd = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <span>
                {{$_strings.invoiceData.nameBankLabel}}<span class="red--text">*</span>
              </span>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                dense
                v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                v-model="form.bankName"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.invoiceData.nameBankLabel)]"
                clearable
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <span>
                {{$_strings.invoiceData.aCLabel}}<span class="red--text">*</span>
              </span>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                dense
                v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                v-model="form.bankAcc"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.invoiceData.aCLabel)]"
                clearable
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <span>
                {{$_strings.invoiceData.aNLabel}}<span class="red--text">*</span>
              </span>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                dense
                v-model="form.bankAtt"
                v-mask="{'alias': 'integer',rightAlign: false}"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.invoiceData.aNLabel)]"
                clearable
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <span>
                {{$_strings.invoiceData.signerOneLabel}}<span class="red--text">*</span>
              </span>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                dense
                v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                v-model="form.signerLeftName"
                :rules="rules.signerLeftName"
                clearable
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <span>
                {{$_strings.invoiceData.signatoryOneLabel}}<span class="red--text">*</span>
              </span>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                dense
                v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                v-model="form.signerLeftPosition"
                :rules="rules.signerLeftPosition"
                clearable
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <span>
                {{$_strings.invoiceData.signerTwoLabel}}<span class="red--text">*</span>
              </span>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                dense
                v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                v-model="form.signerRightName"
                :rules="rules.signerRightName"
                clearable
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" sm="4">
              <span>
                {{$_strings.invoiceData.signatoryTwoLabel}}<span class="red--text">*</span>
              </span>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                outlined
                dense
                v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                v-model="form.signerRightPosition"
                :rules="rules.signerRightPosition"
                clearable
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          small
          color="red white--text"
          @click="dialog = false"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          depressed
          small
          color="primary"
          class="white--text"
          @click="createInvoiceFixedPrice"
          :disabled="isLoading"
        >
          {{$_strings.common.SAVE}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dateFormat } from '@/helper/commonHelpers';
import dayjs from 'dayjs';

export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      menuPeriodEnd: false,
      menuPeriodStart: false,
      rules: {
        signerLeftName: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.signerOneLabel),
          (value) => {
            const pattern = /^[a-z ,.'-]+$/i;
            return pattern.test(value) || 'Tidak boleh menggunakan angka atau spesial character';
          },
        ],
        signerRightName: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.signerTwoLabel),
          (value) => {
            const pattern = /^[a-z ,.'-]+$/i;
            return pattern.test(value) || 'Tidak boleh menggunakan angka atau spesial character';
          },
        ],
        signerLeftPosition: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.signatoryOneLabel),
          (value) => {
            const pattern = /^[a-z ,.'-]+$/i;
            return pattern.test(value) || 'Tidak boleh menggunakan angka atau spesial character';
          },
        ],
        signerRightPosition: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.signatoryTwoLabel),
          (value) => {
            const pattern = /^[a-z ,.'-]+$/i;
            return pattern.test(value) || 'Tidak boleh menggunakan angka atau spesial character';
          },
        ],
      },
    };
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        const currentMonth = dayjs().format('MM');
        const currentYear = dayjs().format('YYYY');
        const startDate = `${currentYear}-${currentMonth}-01`;
        const endDate = `${currentYear}-${currentMonth}-${dayjs().daysInMonth()}`;
        this.form.periodStart = startDate;
        this.form.periodEnd = endDate;
        this.invoiceCommonData();
      }
    },
  },
  methods: {
    dayjs,
    dateFormat,
    createInvoiceFixedPrice() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        this.$dialog.notify.error('Mohon periksa kembali field yang wajib diisi');
        return;
      }
      this.dialog = false;
      this.$emit('createInvoice');
    },
    invoiceCommonData() {
      this.isLoading = true;
      this.$_services.invoiceData.invoiceCommonData()
        .then((res) => {
          this.form.bankAcc = res.data.bankAccountName;
          this.form.bankAtt = res.data.bankAccountNumber;
          this.form.bankName = res.data.bankName;
          this.form.signerLeftName = res.data.firstSignerName;
          this.form.signerLeftPosition = res.data.firstSignerPosition;
          this.form.signerRightName = res.data.secondSignerName;
          this.form.signerRightPosition = res.data.secondSignerPosition;
        }).finally((e) => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style>
</style>
