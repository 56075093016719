<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <span class="font-weight-bold">{{$_strings.invoice.TITLE_CREATE}}</span>
      </v-col>
    </v-row>
    <v-sheet>
      <v-row class="pa-2">
        <v-col cols="12" sm="9" md="8">
          <v-row align="center">
            <v-col
              cols="12"
              md="4"
            >
              <common-auto-complete-items
                type="masterShipper"
                searchName="companyName"
                item-value="id"
                item-text="companyName"
                v-model="filters.companyId"
                dense
                outlined
                hide-details
                class="caption"
                placeholder="Nama Perusahaan Shipper"
                :filter="filterCompanyPagination"
                :items="itemsCompany"
                @updateItems="updateListCompany"
              />
            </v-col>
            <v-col
              cols="auto"
              class="pr-0"
            >
              <v-menu
                ref="menuDateFrom"
                v-model="menuDateFrom"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    hide-details
                    outlined
                    class="caption"
                    placeholder="From Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    :value="dayjs(filters.dateFrom).format('DD-MM-YYYY')"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.dateFrom"
                  no-title
                  :max="filters.dateTo"
                  @change="menuDateFrom = false"
                  locale="id"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="px-1" cols="auto">
              <span>-</span>
            </v-col>
            <v-col
              cols="auto"
              class="px-0"
            >
              <v-menu
                ref="menuDateTo"
                v-model="menuDateTo"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    hide-details
                    class="caption"
                    outlined
                    :value="dayjs(filters.dateTo).format('DD-MM-YYYY')"
                    placeholder="To Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :min="filters.dateFrom"
                  v-model="filters.dateTo"
                  no-title
                  @change="menuDateTo = false"
                  locale="id"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-row align="center" justify-md="end">
            <v-col
              cols="auto"
            >
              <span class="order-checked mr-2">Terpilih : {{checkedValue}} Pesanan</span>
              <v-btn
                :disabled="!checkedValue"
                color="primary"
                elevation="5"
                small
                @click="$refs.dialogCreateInvoice.dialog = true"
              >{{$_strings.invoice.TITLE_CREATE}}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :loading="isLoading"
            :headers="headers"
            calculate-widths
            :items="itemsInvoice"
            item-key="noInvoice"
            :server-items-length="invoiceListTotalEntry"
            :options.sync="pagination"
            :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
          >
            <template v-slot:[`header.checkbox`]="{}">
              <div class="d-flex">
                <span>
                  <v-checkbox
                    color="white"
                    class="d-inline"
                    v-model="checkAll"
                    :disabled="!itemsInvoice.length"
                    @change="handleCheckAll"
                    hide-details
                  />
                </span>
              </div>
            </template>
            <template v-slot:[`item.checkbox`]={item}>
              <v-checkbox v-model="item.checkbox"  class="itemCheck"></v-checkbox>
            </template>
            <template v-slot:[`item.shipmentTitle`]=items>
              <p class="ma-0">{{ items.item.shipmentTitle }}</p>
              <span class="caption">{{ dateFormat(items.item.shipmentDate) }}</span>
            </template>
            <template v-slot:[`item.shipperName`]=items>
              <p class="ma-0">{{ items.item.shipperName }}</p>
              <span>{{ items.item.inputBy }}</span>
            </template>
            <template v-slot:[`item.transporterAbbreviation`]=items>
              <p class="ma-0">{{ items.item.transporterAbbreviation }}</p>
              <span>{{ items.item.inputBy }}</span>
            </template>
            <template v-slot:[`item.transportTypeName`]=items>
              <span>{{ items.item.transportTypeName }}</span>
              <br/>
              <span class="caption">{{ items.item.licencePlate }}</span>
            </template>
            <template v-slot:[`item.grandTotalAmount`]=items>
              <p class="ma-0">Rp. {{ formatAmount(items.item.grandTotalAmount) }}</p>
              <span class="caption">{{ typePriceInvoice(items.item.typePrice) }}</span>
            </template>
            <template v-slot:[`item.status`]=items>
              <p class="ma-0">{{ items.item.status }}</p>
              <span class="caption">{{ dateFormat(items.item.dateStatus) }}</span>
            </template>
            <template v-slot:[`footer.page-text`]="props">
              <span>
                {{$_strings.invoice.ORDER_LIST}}
                <span v-if="itemsInvoice.length">
                  {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
                </span>
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-sheet>
    <DialogCreateInvoice
      ref="dialogCreateInvoice"
      @createInvoice="createInvoice"
      :form="form"
    />
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import DialogCreateInvoice from './Dialog/CreateInvoice.vue';
import {
  thousandSeparated,
  typePriceInvoice,
  dateFormat,
  skipEmptyStringObject,
} from '../../helper/commonHelpers';

export default {
  components: {
    DialogCreateInvoice,
  },
  data() {
    return {
      isLoading: false,
      isLoadingCompanyList: false,
      itemsCompany: [],
      itemsInvoice: [],
      headers: [
        {
          text: this.$_strings.invoice.SELECT,
          value: 'checkbox',
          class: 'white--text primary',
          width: '20px',
          sortable: false,
        },
        {
          text: this.$_strings.invoice.ORDER_NO,
          value: 'shipmentTitle',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.SHIPPER_NAME,
          value: 'shipperName',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.REKANAN_3PL,
          value: 'transporterAbbreviation',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.ADD_TRANSPORT,
          value: 'transportTypeName',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.PRICE,
          value: 'grandTotalAmount',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.INVOICE_DOCUMENT,
          value: 'status',
          class: 'white--text primary text-capitalize',
        },
      ],
      checkAll: false,
      pagination: {
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
      },
      filterCompanyPagination: {
        search: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
      invoiceListTotalEntry: 0,
      menuDateFrom: false,
      menuDateTo: false,
      filters: {
        companyId: '',
        dateFrom: dayjs(`${dayjs().format('YYYY-MM')}-01`).format('YYYY-MM-DD'),
        dateTo: dayjs(`${dayjs().format('YYYY-MM')}-${dayjs().endOf('month').get('D')}`).format('YYYY-MM-DD'),
      },
      form: {
        periodStart: '',
        periodEnd: '',
        companyId: '',
        shipmentList: [],
        bankAcc: '',
        bankAtt: '',
        bankName: '',
        signerLeftName: '',
        signerLeftPosition: '',
        signerRightName: '',
        signerRightPosition: '',
      },
    };
  },
  watch: {
    pagination: {
      handler() {
        if (this.filters.companyId) this.fetchInvoice();
      },
      deep: true,
    },
    filters: {
      handler() {
        this.fetchInvoice();
      },
      deep: true,
    },
    checkedValue() {
      this.checkAll = this.checkedValue === this.itemsInvoice.length;
    },
  },
  computed: {
    checkedValue() {
      return this.itemsInvoice.filter((x) => x.checkbox).length;
    },
  },
  methods: {
    dayjs,
    dateFormat,
    skipEmptyStringObject,
    handleCheckAll(value) {
      if (value) {
        this.itemsInvoice = this.itemsInvoice.map((data) => ({
          ...data,
          checkbox: true,
        }));
        return;
      }
      this.itemsInvoice = this.itemsInvoice.map((data) => ({
        ...data,
        checkbox: false,
      }));
    },
    updateListCompany(items) {
      this.itemsCompany = [...this.itemsCompany, ...items];
    },
    formatAmount(amount) {
      return thousandSeparated(amount);
    },
    typePriceInvoice(typePrice) {
      return typePriceInvoice(typePrice);
    },
    async fetchInvoice() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const { dateFrom, dateTo, companyId } = this.filters;
      if (!companyId) return;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: '',
        dateFrom,
        dateTo,
        companyId,
      };
      if (sortBy.length > 0) {
        const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
        filters.sort = `${sortBy[0]},${isDesc}`;
      }
      try {
        this.isLoading = true;
        const result = await this.$_services.invoice.fetchInvoiceOrder(this.skipEmptyStringObject(filters));
        this.itemsInvoice = result.data.contents.map((data) => ({
          ...data,
          checkbox: false,
        }));
        this.invoiceListTotalEntry = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    createShipmentList() {
      this.form.shipmentList = [];
      this.itemsInvoice.forEach((item) => {
        if (item.checkbox) {
          this.form.shipmentList.push({
            id: item.id,
            shipmentId: item.shipmentId,
            shipmentTitle: item.shipmentTitle,
          });
        }
      });
    },
    createInvoice() {
      const { companyId } = this.filters;
      this.createShipmentList();
      this.form.companyId = companyId;
      this.$root.$loading.show();
      this.$_services.invoice.createInvoice(this.form)
        .then((res) => {
          this.$dialog.notify.success(this.$_strings.invoice.SUCCESS_INVOICE_MESSAGE_TEXT);
          this.$router.push('/main/invoice-customer/list');
        }).finally(() => {
          this.$root.$loading.hide();
        });
    },
  },
};
</script>
